<template>
  <v-app class="form-container">
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
    />

    <label class="item-label mt-6">Question Text</label>
    <Textarea
      :rows="4"
      :cefrLevel="payload.cefr_level"
      v-model="questionText"
    />
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TextBox from "@/components/item/TextBox";
import Textarea from "@/components/item/Textarea";
import { v4 as uuidv4 } from "uuid";
export default {
  data: () => ({
    title: "",
    questionText: "",
  }),
  computed: {
    ...mapState("item", ["payload", "isUpdate", "wordLimits"]),
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    async upload(event) {
      const formData = new FormData();
      const file = event.target.files[0];
      if (!file) throw new Error("File did not load sucessfully.");

      formData.append("author_id", this.user.user_id);
      formData.append("file", file);
      formData.append("content_type", file.type);

      try {
        const response = await this.uploadFile(formData);
        const { file_name, file_location } = response.data;
        this.imageFilename = file_name;
        this.imageFileLocation = file_location;
      } catch (error) {
        console.error(error);
      }
    },
    onSubmit() {
      const data = {
        template_type: "ANSWER_THE_QUESTION",
        static_text: "Answer the question",
        title: this.title,
      }

      if (this.isUpdate) {
        data.questions = [{
          question_id: this.questionId,
          question: this.questionText,
        }]
      } else {
        /**
         * The questions[] array here is hard-coded because
         *  a) it is designed to be static, except for the question text;
         *  b) the API requires it.
         */

         // Note: this.wordLimits is available only when creating new items
        const { min, max } = this.wordLimits.advised
        const advisedWordLimitPrompt = `write ${min}—${max} words.`

        data.questions = [
          {
            question_id: uuidv4(),
            question: this.questionText,
            solution: [],
            metadatas: {
              min_word_limit: this.wordLimits.mandatory.min || 0,
              max_word_limit: this.wordLimits.mandatory.max || 0,
              instructions: [
                '', // @note: This empty row is intentional.
                advisedWordLimitPrompt,
              ]
            }
          },
          {
            question_id: uuidv4(),
            question: "Did they answer the question?",
            question_template: "QUESTION_WITH_RESPONSE",
            data_point: "Q_CONTEXTUAL_BINARY",
            position: 0,
            solution: [],
            metadatas: {
              title: "This person is answering a question",
            },
          },
          {
            question_id: uuidv4(),
            question: "How well did they do?",
            question_template: "STAR_RATING_WITH_RESPONSE",
            data_point: "RATING_OVERALL_5STAR",
            position: 1,
            solution: [],
            metadatas: {
              title: "This person is answering a question",
            },
          },
        ]
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title, questions } = this.payload
      this.title = title;

      const { question, question_id } = questions.find(({ question_template }) => question_template === null)
      this.questionText = question;
      this.questionId = question_id;
    }
  },
  components: {
    TextBox,
    Textarea,
  }
};
</script>

<style lang="scss" scoped>
label {
  color: #264d98;
  &.ghost {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
  }
}

.form-container {
  max-width: 550pt;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
